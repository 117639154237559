import '@/scss/core/_main.scss';
import {createApp} from 'vue';
import Root from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import {AntDesignPlugin} from './plugins/antDesign';

const app = createApp(Root);
app.use(store);
app.use(router);
app.use(AntDesignPlugin);
app.mount('#app');
