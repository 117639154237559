import axios from 'axios';
import store from '@/store';
import router from '@/router';
import {notification} from 'ant-design-vue';

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 60 * 1000,
});

axiosInstance.interceptors.request.use((config) => {
  const token = store.state.accessToken;
  if (token) {
    const configAuthorized = {...config};
    if (configAuthorized && configAuthorized.headers) {
      configAuthorized.headers['X-Auth-Token'] = `${token}`;
    }
    return configAuthorized;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      store.dispatch('resetAuthState').then(() => {
        router.push({name: 'Login'});
      });
    } else {
      const message = error.response.data.message || error.response.data.error;
      if (message) {
        notification['error']({message});
      }
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
