import {App, Plugin} from 'vue';
import {
  Layout,
  Button,
  Spin,
  Col,
  Row,
  Drawer,
  Table,
  Form,
  InputNumber,
  Menu,
  message,
  Input,
  Popconfirm,
  Modal,
  Pagination,
  Checkbox,
  Select,
  Tabs,
  Tooltip,
} from 'ant-design-vue';

export const AntDesignPlugin: Plugin = {
  install: (app: App) => {
    app.use(Layout);
    app.use(Button);
    app.use(Spin);
    app.use(Col);
    app.use(Row);
    app.use(Drawer);
    app.use(Table);
    app.use(Form);
    app.use(InputNumber);
    app.use(Menu);
    app.use(Form);
    app.use(Input);
    app.use(Spin);
    app.use(Popconfirm);
    app.use(Modal);
    app.use(Pagination);
    app.use(Checkbox);
    app.use(Select);
    app.use(Tabs);
    app.use(Tooltip);

    app.config.globalProperties.$message = message;
  },
};
