import {AxiosResponse} from 'axios';
import ApiService from '@/services/_helper/api.service';

export default class AuthService {
  static login(payload: {username: string; password: string}): Promise<AxiosResponse> {
    return ApiService.post('/login', payload);
  }
  static logout(): Promise<AxiosResponse> {
    return ApiService.get('/logout');
  }
}
