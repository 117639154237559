import {createStore} from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import createMutationsSharer from 'vuex-shared-mutations';
import {RootState} from '@/types/store/RootState';
import AdminInterface from '@/types/api/AdminInterface';
import AuthService from '@/services/auth.service';

const getDefaultState = () => {
  return {
    user: {} as AdminInterface,
    accessToken: '',
    refreshToken: '',
  } as RootState;
};

export default createStore({
  state: getDefaultState(),
  mutations: {
    setAccessToken(state: RootState, accessToken: string) {
      state.accessToken = accessToken;
    },
    setRefreshToken(state: RootState, refreshToken: string) {
      state.refreshToken = refreshToken;
    },
    resetState(state: RootState) {
      state = Object.assign(state, getDefaultState());
    },
    setUsername(state: RootState, username: string) {
      state.user.username = username;
    },
  },
  actions: {
    login({commit}, payload: {username: string; password: string}) {
      return AuthService.login(payload).then((res) => {
        if (res.data.token) {
          commit('setAccessToken', res.data.token);
          commit('setUsername', payload.username);
        }
      });
    },
    resetAuthState({commit}) {
      commit('resetState');
    },
    logout({commit}) {
      return AuthService.logout().then(() => {
        commit('resetState');
      });
    },
  },
  plugins: [createPersistedState(), createMutationsSharer({predicate: ['resetState']})],
});
