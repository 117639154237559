import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';
import store from '@/store';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "layouts" */ '@/layouts/MainLayout.vue'),
    meta: {private: true},
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
        meta: {
          private: true,
          title: 'Dashboard - Savii Admin Panel',
        },
      },
      {
        path: '/localisation',
        name: 'Localisation',
        component: () => import(/* webpackChunkName: "localisation" */ '@/views/Localisation.vue'),
        meta: {
          private: true,
          title: 'Localisation - Savii Admin Panel',
        },
      },
      {
        path: '/content',
        name: 'Content',
        component: () => import(/* webpackChunkName: "content" */ '@/views/Content.vue'),
        meta: {
          private: true,
          title: 'Content - Savii Admin Panel',
        },
      },
      {
        path: '/users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "users" */ '@/views/UserList.vue'),
        meta: {
          private: true,
          title: 'Users - Savii Admin Panel',
        },
      },
      {
        path: '/users/:id',
        name: 'UserDetails',
        component: () => import(/* webpackChunkName: "users" */ '@/views/UserDetails.vue'),
        meta: {
          private: true,
          title: 'User Profile - Savii Admin Panel',
        },
      },
      {
        path: '/consent',
        name: 'Consent',
        component: () => import(/* webpackChunkName: "consent" */ '@/views/Consent.vue'),
        meta: {
          private: true,
          title: 'Consent - Savii Admin Panel',
        },
      },
      {
        path: '/system-settings',
        name: 'System Settings',
        component: () => import(/* webpackChunkName: "system-settings" */ '@/views/SystemSettings.vue'),
        meta: {
          private: true,
          title: 'System Settings - Savii Admin Panel',
        },
      },
    ],
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "layouts" */ '@/layouts/SecondaryLayout.vue'),
    children: [
      {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/Login.vue'),
        meta: {
          public: true,
          title: 'Login - Savii Admin Panel',
        },
      },
      {
        path: '/:pathMatch(.*)*',
        component: () => import(/* webpackChunkName: "404" */ '@/views/NotFount.vue'),
        meta: {
          title: 'Page not found - Savii Admin Panel',
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = store.state.accessToken;
  const isPublic = to.matched.some((record) => record.meta.public);
  const isPrivate = to.matched.some((record) => record.meta.private);

  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title as string;
  }

  if (isPublic && token) {
    next('/');
  } else if (isPrivate && !token) {
    next({name: 'Login'});
  } else {
    next();
  }
});

export default router;
